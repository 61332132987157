<template>
    <div>
        <footer id="footer">
            <div class="bg-sec">
                <div class="container">
                <h2>BAGIAN ORGANISASI <strong >KONAWE SELATAN </strong></h2>
                </div>
            </div>
            </footer>
            <footer id="footer-down">
            <h2>Follow Us On</h2>
            <ul class="social-icon" >

                <li :class="data.keterangan +' hvr-pulse'" v-for="data in list_links" :key="data.id">
                    
                    <a :href="data.isi" target="_blank">
                        <i :class="'fa fa-'+data.keterangan"></i>
                    </a>
                </li>
                
            </ul>
            <!-- <ul class="social-icon">
                <li class="facebook hvr-pulse"><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                <li class="twitter hvr-pulse"><a href="#"><i class="fa fa-twitter"></i></a></li>
                <li class="linkedin hvr-pulse"><a href="#"><i class="fa fa-linkedin"></i></a></li>
                <li class="google-plus hvr-pulse"><a href="#"><i class="fa fa-google-plus"></i></a></li>
                <li class="youtube hvr-pulse"><a href="#"><i class="fa fa-youtube"></i></a></li>
                <li class="instagram hvr-pulse"><a href="#"><i class="fa fa-instagram"></i></a></li>
                <li class="behance hvr-pulse"><a href="#"><i class="fa fa-behance"></i></a></li>
            </ul> -->
            <p> &copy; Copyright 2023 Cosmix - Created By : <a href="https://diskominfo.konaweselatankab.go.id/" target="_blank">Diskominfosandi Konawe Selatan</a> </p>
            </footer>  
    </div>
</template>

<script>

    // const URL_APP = 'http://localhost:5005/';   
    const URL_APP = 'https://server-web.konaweselatankab.go.id/';	
    const URL_LINKS = URL_APP + "api/v1/publish_ortala_links/";

export default {
  name: 'Home',


  data() {
			return {
                id: "",
                list_links: [],
                
			}
		},

    methods: {
        getLinks: function() {
                fetch(URL_LINKS+"view", {
                    method: "Get",
                    headers: {
                        "content-type": "application/json",
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_links = res_data.data
                        console.log(this.list_links);

                });
            },
            


        },



		mounted() {

            this.getLinks();
            

		},


}
</script>