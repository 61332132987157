<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2>Halaman <span>Berita</span></h2>
                    <div class="line"></div>
                    <p><span><strong>K</strong></span>umpulan berita dari Bagian Organisasi Kabupaten Konawe Selatan</p>
                </div>
                </div>
                <div class="row">
                    
                    <div class="col-md-12">
                        <br>
                <div class="row">
                    <div class="col-xs-12 col-md-12 pull-right form-group">
                        
                        <input v-model="cari_value" @keyup="cari_data()" class="form-control form-control-lg" type="text" placeholder="Cari Data">
                    </div>
                    

                </div>

                        <div class="col-md-4 blog-sec" v-for="data in list_data" :key="data.id">
                            <div class="blog-info"> 
                                <!-- <img src="images/Blog/1.jpg" class="img-responsive" alt=""> -->
                                <img :src="file_path+data.foto" class="img-responsive" alt="" style="height: 250px;">
                                <div class="data-meta">
                                {{convert_tgl(data.createAt)}}
                                </div>
                                <a @click="push_berita(data.id)" href="javascript:void(0);">
                                <h5>{{data.judul}}</h5>
                                </a>
                                <ul class="blog-icon">
                                <li><i class="fa fa-pencil"></i><a href="javascript:void(0);">
                                    <h6>{{data.createBy}}</h6>
                                    </a></li>
                                
                                </ul>
                                <p>{{data.deskripsi}}</p>
                                <a @click="push_berita(data.id)" href="javascript:void(0);" class="btn-blg">Selengkapnya</a> </div>
                            
                            </div>
                            
                            
                        </div>
                    </div>

                    <hr>
            <br>
            <div class="text-center">
                    <button @click="btn_prev" type="button" class="btn btn-xs bg-blue waves-effect" id="pref">
                        <i class="fa fa-arrow-circle-o-left" aria-hidden="true" style="font-size:150%"></i>
                    </button>
                    &nbsp;
                    <span style="font-size: 85%; color: #a5a5a5">
                        <b>{{page_first}} - {{page_last}}</b>
                    </span>
                    &nbsp;
                    <button @click="btn_next" type="button" class="btn btn-xs bg-blue waves-effect" id="next">
                        <i class="fa fa-arrow-circle-o-right" aria-hidden="true" style="font-size:150%"></i>
                    </button>
            </div>
                <br>
            <hr>
            </div>
        </section>
    </div>
</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

    //const URL_APP = 'http://localhost:5005/';   
	
	const URL_APP = 'https://server-web.konaweselatankab.go.id/';	
	
    const UNIT_KERJA = 'PujKg9YgpsKT8BkCE'; // ORTALA
    
    
    const ADD_URL = URL_APP + "api/v1/web_publish_berita/";
    const FILE_LOAD = URL_APP + "uploads/";
    

export default {
  name: 'Home',
  // components: {
  //   HelloWorld
  // }

  data() {
			return {
                data_load : false,
                form : {
                    id : '',
                    judul : '',
                    sumber : '',
                    deskripsi : '',
                    isi : '',
                    foto : '',
                    unit_kerja : '',
                    status : '',
                    keterangan : '',
                    createBy : '',
                    createAt : '',
                },
                id :'',
                cek_load_data : true,
                file_old: "",
                file_path: FILE_LOAD,

                unit_kerja : UNIT_KERJA,

                list_data: [],
                page_first: 1,
                page_last: 0,
                cari_value: "",
			}
		},
		methods: {
			getView: function() {
                this.cek_load_data = true;
                fetch(ADD_URL + "viewOPD", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                        body: JSON.stringify({
                        data_ke: this.page_first,
                        cari_value: this.cari_value,
                        unit_kerja : this.unit_kerja
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_data = res_data.data;
                        this.page_last = res_data.jml_data;
                        this.cek_load_data = false;
                        // console.log(res_data);
                });
            },
            push_berita : function(id){
                this.$router.push('/isi_berita?id='+id);
            },


            // ================== PAGINASI ====================
                alertku: function(type, title) {
                this.$swal({
                    type: type,
                    title: title,
                    showConfirmButton: false,
                    timer: 800
                });
                },

                btn_prev : function(){
                        this.cek_load_data = true;
                    if(this.page_first>1){
                        this.page_first--
                    }else{
                        this.page_first = 1;
                    }
                    this.getView();
                },

                btn_next : function(){
                    
                    if(this.page_first >= this.page_last){
                        this.page_first == this.page_last
                    }else{
                        this.page_first++;
                    }
                    this.getView();
                },

                cari_data : function(){

                    this.page_first = 1;
                    this.getView();
                },
                onFileSelected: function(event) {
                this.form.file = event.target.files[0];
                },
                convert_tgl: function(dateString) {
                    var date = new Date(dateString);
                    var getBulan = date.getMonth() + 1; var bulan = '';
                    if (getBulan == '1') {bulan = 'Jan'} 
                    else if(getBulan == '2') {bulan = 'Feb'}
                    else if(getBulan == '3') {bulan = 'Mar'}
                    else if(getBulan == '4') {bulan = 'Apr'}
                    else if(getBulan == '5') {bulan = 'Mei'}
                    else if(getBulan == '6') {bulan = 'Jun'}
                    else if(getBulan == '7') {bulan = 'Jul'}
                    else if(getBulan == '8') {bulan = 'Agt'}
                    else if(getBulan == '9') {bulan = 'Sep'}
                    else if(getBulan == '10') {bulan = 'Okt'}
                    else if(getBulan == '11') {bulan = 'Nov'}
                    else if(getBulan == '12') {bulan = 'Des'}



                    return date.getDate() + " " + bulan + " " + date.getFullYear();
                    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
                }
            // ================== PAGINASI ====================













		},
		mounted() {
            this.getView();

		},


}
</script>
