<template>
    <div class="container" style="padding: 20pt;margin-top: 40pt;">
        <br> <br>

        <div class="col-md-8 col-md-offset-2">
            <div class="heading text-center">
                <h2>Galeri <span>Video</span></h2>
                <div class="line"></div>
                <br>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 ab-sec">
                <br>
                <div class="row">
                    <div class="col-xs-12 col-md-12 pull-right form-group">
                        
                        <input v-model="cari_value" @keyup="cari_data()" class="form-control form-control-lg" type="text" placeholder="Cari Data">
                    </div>
                    

                </div>
                <div class="row">
                    <div class="col-md-12">

                    
                        <div class="row equal-cols" v-if="!cek_load_data" >
                            <section id="portfolio">
                                <div class="portfolio-items">

                                    <div class="col-md-4 col-sm-6 col-xs-12 portfolio-item creative" v-for="data in list_data" :key="data.id">
                                        <div class="portfolio-item-inner"> 
                                            <iframe width="100%" height="200px" :src="data.link" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>     
                                            
                                        </div>
                                        <div class="text-center"> 
                                            <h6>{{data.judul}}</h6>
                                        </div>
                                    </div>
                                    <!--/.portfolio-item-->

                                </div>
                            </section>


                            

                        </div>

                        <div class="row text-center" v-if="cek_load_data" >
                            <img src="img/loading-plan.gif" width="450px">
                        </div>


                        </div>

                </div>
                
                
            </div>
        </div>

        <hr>
            <br>
            <div class="text-center">
              <button @click="btn_prev" type="button" class="btn btn-xs bg-blue waves-effect" id="pref">
                           <i class="fa fa-arrow-circle-o-left" aria-hidden="true" style="font-size:150%"></i>
                       </button>
                       &nbsp;
                       <span style="font-size: 85%; color: #a5a5a5">
                           <b>{{page_first}} - {{page_last}}</b>
                       </span>
                       &nbsp;
                       <button @click="btn_next" type="button" class="btn btn-xs bg-blue waves-effect" id="next">
                           <i class="fa fa-arrow-circle-o-right" aria-hidden="true" style="font-size:150%"></i>
                       </button>
            </div>
            <br>
        <hr>
    </div>
</template>

<script>

const URL_APP = 'https://server-web.konaweselatankab.go.id/';
//const URL_APP = 'http://localhost:5005/';    
const ADD_URL = URL_APP + "api/v1/publish_ortala_video/";
const FILE_LOAD = URL_APP + "uploads/";


export default {

    data() {
        return {
            data_load : false,
            form : {
                id : '',
                judul : '',
                deskripsi : '',
                file : '',
                unit_kerja : '',
                status : '',
                createBy : '',
                createAt : '',
            },
            id :'',
            cek_load_data : true,
            file_old: "",
            file_path: FILE_LOAD,

            list_data: [],
            page_first: 1,
            page_last: 0,
            cari_value: "",
        }
    },
    methods: {
    getView: function() {
        this.cek_load_data = true;
        fetch(ADD_URL + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            },
            body: JSON.stringify({
            data_ke: this.page_first,
            cari_value: this.cari_value,
            kategori: this.kategori,
            })
        })
        .then(res => res.json())
        .then(res_data => {
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
            this.cek_load_data = false;
            // console.log(data);
        });
    },

    selectData: function(data) {
        console.log(data);
        this.form = {
            id : data.id,
            judul : data.judul,
            deskripsi : data.deskripsi,
            file : data.file,
            unit_kerja : data.unit_kerja,
            status : data.status,
            createBy : data.createBy,
            createAt : data.createAt,
        };
      },
      ke_alamat : function(data){
            window.open(data);
        },

    mdl_exit: function() {
      this.judul = "";
      this.uraian = "";
      this.file = "";
      this.file_old = "";
    },

  // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
            this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){

        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
    onFileSelected: function(event) {
      this.form.file = event.target.files[0];
    },
    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      return date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    },
  // ================== PAGINASI ====================

  // ================== MODAL ====================
      buka_modal: function(modalku) {
        var modal = document.getElementById(modalku);
        modal.style.display = "block";
      },

      tutup_modal: function(modalku) {
        var modal = document.getElementById(modalku);
        modal.style.display = "none";
      },

  // ================== MODAL ====================
  },
  // =============================================================== METHODS =========================================================================

  mounted() {

        this.getView();

    }
};
</script>
