<template>
    <div>
        <section class="">
            <div class="container" style="padding: 20pt;margin-top: 40pt;">
                <div class="col-md-8 col-md-offset-2">
                <div class="heading text-center">
                    <h2><span>{{ form.jenis }}</span></h2>
                    <div class="line"></div>
                    <br>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12 ab-sec">
                    <div class="col-md-12">
                        <h4 class="text-center">{{ form.judul }}</h4>

                        <p  v-html="uraian"></p>

                        <button @click="selectData(data)" class="btn-danger" title="Lihat Data" data-toggle="modal" data-target="#m_lihat_data">
                                            Lihat Lampiran <i class="fa fa-download" aria-hidden="true"></i>
                                        </button>
                    </div>

                    
                    
                </div>
                </div>
            </div>

            <div class="modal fade" id="m_lihat_data"  role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Detail File</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <iframe :src="file_path+form.file" width="100%" height="500px"></iframe>
                                </div>
                                <div class="modal-footer">

                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
        </section>
    </div>
</template>

<script>

    //const URL_APP = 'http://localhost:5005/';   
    import store from '@/store/index.js';
    const URL_APP = store.state.url.URL_APP;
    const URL = URL_APP + "api/v1/publish_ortala_layanan/";
    
    const FILE_LOAD = URL_APP + "uploads/";
    

export default {  

    data() {
            return {
                list_data: [],
                page_first: 1,
                page_last: 0,
                cari_value: "",
                uraian: "",
                // unit: "Sekretariat",
                id:"",

                form : {
                    id : '',
                    jenis : '',
                    judul : '',
                    sumber : '',
                    isi : '',
                    deskripsi : '',
                    file : '',
                    unit_kerja : '',
                    status : '',
                    keterangan : '',
                },

                file_path: FILE_LOAD,
            }
        },
        methods: {
            getView: function() {
                fetch(URL, {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    },
                    body : JSON.stringify({
                        id: this.id,
                    }),
                })
                    .then(res => res.json())
                    .then(res_data => {
                    this.cek_load_data = false;

                    var tes = res_data[0];
                    this.uraian = tes.uraian;
                    this.form.judul = tes.judul;
                    this.form.jenis = tes.jenis;
                    this.form.file = tes.file;
                    });
                },
            
            selectData: function(data) {
                this.form = {
                    id : data.id,
                    // kategori : data.kategori,
                    judul : data.judul,
                    deskripsi : data.deskripsi,
                    jenis: data.jenis,
                    file : data.file,
                };
            },

        },
    // =============================================================== METHODS =========================================================================

    mounted() {
        this.id = this.$route.query.id;
        this.getView();



    }


}
</script>