import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Berita from '../views/berita/Berita.vue'
import isi_berita from '../views/berita/isi_berita.vue'

// PROFILE
import struktur_organisasi from '../views/profile/struktur_organisasi.vue'
import tugas_pokok from '../views/profile/tugas_pokok.vue'
import tujuan_sasaran from '../views/profile/tujuan_sasaran.vue'
import visi_misi from '../views/profile/visi_misi.vue'



// DOKUMEN LAINNYA
import ortala_dokumen from '../views/ortala_dokumen.vue'

// GALERI
import foto from '../views/galeri/foto.vue'
import video from '../views/galeri/video.vue'

// LAYANAN
import layanan from '../views/layanan/layanan.vue'

Vue.use(VueRouter)

const routes = [

// LAYANAN
{
  path: '/layanan',
  name: 'layanan',
  component: layanan
},




// 

  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Berita',
    name: 'Berita',
    component: Berita
  },
  {
    path: '/isi_berita',
    name: 'isi_berita',
    component: isi_berita
  },
  {
    path: '/ortala_dokumen',
    name: 'ortala_dokumen',
    component: ortala_dokumen
  },
  {
    path: '/struktur_organisasi',
    name: 'struktur_organisasi',
    component: struktur_organisasi
  },
  {
    path: '/tugas_pokok',
    name: 'tugas_pokok',
    component: tugas_pokok
  },
  {
    path: '/tujuan_sasaran',
    name: 'tujuan_sasaran',
    component: tujuan_sasaran
  },
  {
    path: '/visi_misi',
    name: 'visi_misi',
    component: visi_misi
  },



// 
{
  path: '/foto',
  name: 'foto',
  component: foto
},
{
  path: '/video',
  name: 'video',
  component: video
},


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
