<template>
    <div>
      
        <div class="container" style="padding: 20pt;margin-top: 40pt;">
          <div class="row">
            <div class="col-md-8">
  
              <div class="posts-content single-post">
                <article class="post-wrapper">
                  <div class="text-center">
                      <img v-if="cek_load_data" src="img/loading-plan.gif" width="450px">
                  </div>
                  <header class="entry-header-wrapper clearfix">
                    <div class="author-thumb waves-effect waves-light">
                      <a href="javascript:void(0);">
                        <!-- <img :src="file_path+form.foto" alt /> -->
                        <img :src="file_path+form.foto" class="img-responsive" alt="">
                      </a>
                    </div>
                    <div class="entry-header">
                      <h2 class="entry-title">{{form.judul}}</h2>
                      <div class="entry-meta">
                        <ul class="list-inline">
                          <li>
                            <i class="fa fa-user"></i>
                            <a href="javascript:void(0);">{{form.createBy}}</a>
                          </li>
                          <li>
                            <i class="fa fa-clock-o"></i>
                            <a href="javascript:void(0);">{{convert_tgl(form.createAt)}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </header>
  
                  <div class="entry-content isi_berita">
                    <p v-html="form.isi"></p>
  
                  </div>
  
  
  
  
  
                  <hr>
  <social-sharing url="https://konaweselatankab.go.id/#/lihatberita?id=5q51ac03k4p1hpx5" inline-template>
    <div>
      <network network="facebook">
        <i class="fa fa-fw fa-facebook"></i> Facebook
      </network>
      <network network="googleplus">
        <i class="fa fa-fw fa-google-plus"></i> Google +
      </network>
      <network network="linkedin">
        <i class="fa fa-fw fa-linkedin"></i> LinkedIn
      </network>
      <network network="pinterest">
        <i class="fa fa-fw fa-pinterest"></i> Pinterest
      </network>
      <network network="reddit">
        <i class="fa fa-fw fa-reddit"></i> Reddit
      </network>
      <network network="twitter">
        <i class="fa fa-fw fa-twitter"></i> Twitter
      </network>
      <network network="vk">
        <i class="fa fa-vk"></i> VKontakte
      </network>
      <network network="weibo">
        <i class="fa fa-weibo"></i> Weibo
      </network>
      <network network="whatsapp">
        <i class="fa fa-fw fa-whatsapp"></i> Whatsapp
      </network>
    </div>
  </social-sharing>
  
  
  
                  
                </article>
  
  
              </div>
            </div>
            <div class="col-md-4">
              <div class="tt-sidebar-wrapper" role="complementary">
                
  
                <div class="widget widget_tt_popular_post">
                  <div class="tt-popular-post border-bottom-tab">
                    <ul class="nav nav-tabs hidden-xs">
                      <li class="active">
                        <a href="#tt-popular-post-tab1" data-toggle="tab" aria-expanded="true">Terbaru</a>
                      </li>
                      <li class>
                        <a href="#tt-popular-post-tab2" data-toggle="tab" aria-expanded="false">Populer</a>
                      </li>
                    </ul>
                    <div class="panel-group visible-xs" id="undefined-accordion"></div>
  
                    <div class="tab-content">
                      <div id="tt-popular-post-tab1" class="tab-pane fade active in">
                        <br>
                        <div class="media" v-for="data in list_data" :key="data.id">
                          <a class="media-left" href="javascript:void(0);" @click="push_berita(data.id)">
                            <!-- <img  :src="file_path+data.foto" alt /> -->
                            <img :src="file_path+data.foto" class="rounded-circle"  style="width: 100px; height:50px" alt="">
                          </a>
                          <div class="media-body">
                            <h6>
                              <a href="javascript:void(0);" @click="push_berita(data.id)">{{data.judul}}</a>
                            </h6>
                          </div>
                        </div>
                      </div>
  
  
                    </div>
                  </div>
                </div>
                <div class="widget widget_categories">
                  <h3 class="widget-title">Categories</h3>
                  
  
  
  <a href="https://www.facebook.com/sharer/sharer.php?u=https://konaweselatankab.go.id/#/lihatberita?id=i33wt9e4k13bmpod" target="_blank">
    Share on Facebook
  </a>
  
  
  
  
                      <social-sharing
                        url="https://konaweselatankab.go.id/#/lihatberita?id=2vhahqjgn4k2dd5rlx"
                        media ="https://upload.wikimedia.org/wikipedia/commons/f/f0/Lambang_Kabupaten_Konawe_Selatan.png"
                        title="INI JUDUL BERITA"
                        description="Ini deskripsi dari berita."
                        quote="Deskripsi Quote"
                        twitter-user="vuejs"
                        inline-template>
                      <div>
                          <network network="email">
                              <i class="fa fa-envelope"></i> Email
                          </network>
                          <network network="facebook">
                            <i class="fa fa-facebook"></i> Facebook
                          </network>
                          <network network="googleplus">
                            <i class="fa fa-google-plus"></i> Google +
                          </network>
                          <network network="line">
                            <i class="fa fa-line"></i> Line
                          </network>
                          <network network="linkedin">
                            <i class="fa fa-linkedin"></i> LinkedIn
                          </network>
                          <network network="odnoklassniki">
                            <i class="fa fa-odnoklassniki"></i> Odnoklassniki
                          </network>
                          <network network="pinterest">
                            <i class="fa fa-pinterest"></i> Pinterest
                          </network>
                          <network network="reddit">
                            <i class="fa fa-reddit"></i> Reddit
                          </network>
                          <network network="skype">
                            <i class="fa fa-skype"></i> Skype
                          </network>
                          <network network="sms">
                            <i class="fa fa-commenting-o"></i> SMS
                          </network>
                          <network network="telegram">
                            <i class="fa fa-telegram"></i> Telegram
                          </network>
                          <network network="twitter">
                            <i class="fa fa-twitter"></i> Twitter
                          </network>
                          <network network="vk">
                            <i class="fa fa-vk"></i> VKontakte
                          </network>
                          <network network="weibo">
                            <i class="fa fa-weibo"></i> Weibo
                          </network>
                          <network network="whatsapp">
                            <i class="fa fa-whatsapp"></i> Whatsapp
                          </network>
                      </div>
                    </social-sharing>
  
  
  
  
                </div>
  
              </div>
            </div>
          </div>
        </div>
      
    </div>
  </template>
  
  
  
  <script>
  
	const URL_APP = 'https://server-web.konaweselatankab.go.id/';
    // const URL_APP = 'http://localhost:5005/';      
        const UNIT_KERJA = 'PujKg9YgpsKT8BkCE'; // ORTALA

      const ADD_URL = URL_APP + "api/v1/web_publish_berita/";
  
      const FILE_LOAD = URL_APP+'uploads/';
  
      export default {
        name: "web_pengumuman",
        //   title: "My page title",
        data: function () {
          
          return {
            title: 'My Title',
            myData: '',
  
  
                id : this.$route.query.id,
                data_load : false,
                form : {
                    id : '',
                    judul : '',
                    sumber : '',
                    deskripsi : '',
                    isi : '',
                    foto : '',
                    unit_kerja : '',
                    status : '',
                    keterangan : '',
                    createBy : '',
                    createAt : '',
                },
                unit_kerja : UNIT_KERJA,
                // id :'',
                cek_load_data : true,
                cek_load_data_list : true,
                file_old: "",
                file_path: FILE_LOAD,
  
                list_data: [],
                page_first: 1,
                page_last: 0,
                cari_value: "",
          }
        },
  
  
        // =============================================================== METHODS =========================================================================
        methods: {
  
  
  
            getList: function() {
                this.cek_load_data_list = true;
                fetch(ADD_URL + "viewOPD", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                    body: JSON.stringify({
                      data_ke: this.page_first,
                      cari_value: this.cari_value,
                      unit_kerja : this.unit_kerja
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_data = res_data.data;
                        this.page_last = res_data.jml_data;
                        this.cek_load_data_list = false;
                        // console.log(res_data);
                });
            },
            getView: function() {
                this.cek_load_data = true;
                fetch(ADD_URL + "isi_berita", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    },
                    body: JSON.stringify({
                        id: this.$route.query.id,
                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                    var berita = res_data[0];
                    //   console.log(berita);
                    this.form.createAt = berita.createAt;
                    this.form.createBy = berita.createBy;
                    this.form.deskripsi = berita.deskripsi;
                    this.form.isi = berita.isi;
                    this.form.judul = berita.judul;
                    this.form.sumber = berita.sumber;
                    this.form.foto = berita.foto;
                    this.cek_load_data = false;
  
                    // this.myData = berita.judul;
                    this.getAsyncData();
                });
            },
            getAsyncData: function () {
              var self = this;
              var deskripsi = this.form.judul;
              window.setTimeout(function () {
                self.myData = deskripsi
                self.$emit('updateHead')
              }, 3000)
            },
            push_berita : function(id){
                    this.$router.push('/isi_berita?id='+id);
                    this.getView();
            },
  
            to_facebook : function () {
                window.open("http://www.facebook.com/sharer.php?u="+this.HOST, '_blank');
            },
            to_twitter : function(){
                // alert(this.HOST)
                window.open("https://twitter.com/share?url="+this.HOST+"&amp;text="+this.judul, '_blank');
            },
  
            // ================== PAGINASI ====================
                alertku: function(type, title) {
                this.$swal({
                    type: type,
                    title: title,
                    showConfirmButton: false,
                    timer: 800
                });
                },
  
                btn_prev: function() {
                if (this.page_first > 1) {
                    this.page_first--;
                } else {
                    this.page_first = 1;
                }
                this.getView();
                },
  
                btn_next: function() {
                if (this.page_first >= this.page_last) {
                    this.page_first == this.page_last;
                } else {
                    this.page_first++;
                }
                this.getView();
                },
  
                cari_data: function() {
                this.getList();
                },
                onFileSelected : function(event){
                    this.foto = event.target.files[0];
                    console.log(this.foto);
                },
                convert_tgl : function(dateString){
                    var date = new Date(dateString);
                    var getBulan = date.getMonth() + 1; var bulan = '';
                    if (getBulan == '1') {bulan = 'Jan'}
                    else if(getBulan == '2') {bulan = 'Feb'}
                    else if(getBulan == '3') {bulan = 'Mar'}
                    else if(getBulan == '4') {bulan = 'Apr'}
                    else if(getBulan == '5') {bulan = 'Mei'}
                    else if(getBulan == '6') {bulan = 'Jun'}
                    else if(getBulan == '7') {bulan = 'Jul'}
                    else if(getBulan == '8') {bulan = 'Agt'}
                    else if(getBulan == '9') {bulan = 'Sep'}
                    else if(getBulan == '10') {bulan = 'Okt'}
                    else if(getBulan == '11') {bulan = 'Nov'}
                    else if(getBulan == '12') {bulan = 'Des'}
  
  
  
                    return date.getDate() + " " + bulan + " " + date.getFullYear();
                },
  
            // ================== PAGINASI ====================
        },
        // =============================================================== METHODS =========================================================================
  
        mounted() {
            this.getView();
            this.getList();
           
        },
       
  
      };
  
  
  
      </script>
  
  
      <style>
      .img_berita img {
          width: 100% !important;
          height: auto !important;
      }
  </style>
  