<template>
  <div class="home">
    <!--Slider-Start-->
<section id="slider">
  <div id="home-carousel" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">

    
        <div v-for="(data, idx) in list_slider" :key="data.id" :class="cek_active(idx) +' item'">
          <!-- <img :src="file_path+data.file" style="width: 100%;" alt="Banner Image Here" class="cssnya"> -->
          <img :src="file_path+data.file"  alt="Banner Image Here" class="cssnya">
          <div class="carousel-caption container">
            <div class="row">
              <div class="col-md-7 col-sm-12 col-xs-12">
                <h1>{{data.judul}}</h1>
                <!-- <h2>Creative Themes</h2> -->
                <p>{{data.deskripsi}}</p>
              </div>
            </div>
          </div>
        </div>

      <!-- <div class="item" style="background-image:url(images/Slider/03.jpg)">
        <div class="carousel-caption container">
          <div class="row">
             <div class="col-md-7 col-sm-12 col-xs-12">
              <h1>We Are Cosmix</h1>
              <h2>Creative Themes</h2>
              <p>Cosmix – A One Page Parallax, HTML5 and Responsive Template suitable for any creative business agency. Multiple page also included in this theme with lots of CSS and JQuery animations</p>
            </div>
          </div>
        </div>
      </div> -->
      <a class="home-carousel-left" href="#home-carousel" data-slide="prev"><i class="fa fa-angle-left"></i></a> <a class="home-carousel-right" href="#home-carousel" data-slide="next"><i class="fa fa-angle-right"></i></a> </div>
  </div>
  <!--/#home-carousel-->
</section>
<!--About-Section-Start-->
<section id="about">
  <div class="container">
    <div class="col-md-8 col-md-offset-2">
      <div class="heading">
        <h2>Tentang <span style="color: #115e8d;">Bagian Organisasi</span></h2>
        <div class="line"></div>
        
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 ab-sec">
        <div class="col-md-12">
          <p class="wow fadeInDown" data-wow-duration="1000ms" data-wow-delay="300ms" v-html="tentang_isi"></p>
          <!-- <p><span><strong>L</strong></span>orem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.Lorem Ipsum is simply dummy text of the printing and typesetting industry. book. </p> -->
        </div>
        <!-- <div class="col-md-6 ab-sec-img wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="300ms"><img src="images/Aboutus/01.jpg" alt=""> </div> -->
      </div>
    </div>
  </div>
</section>
<!--About-Sec-2-Start-->
<div class="bg-sec">
  <div class="container">
    <div class="col-md-12 col-sm-12 col-xs-12 text-center">
      <h3>BAGIAN ORGANISASI KABUPATEN KONAWE SELATAN</h3>
      <p>Selamat Datang di Website Dinas Bagian Organisasi Konawe Selatan</p>
    </div>
    
  </div>
</div>
<!--Service-Section-Start-->
<section id="portfolio">
  <div class="container">
    <div class="col-md-8 col-md-offset-2">
      <div class="heading">
        <h2>SAMBUTAN <span style="color: #115e8d;">KEPALA BAGIAN ORGANISASI</span></h2>
        <div class="line"></div>
        <br/>
      </div>
    </div>
    

    <div class="experience">
      <div class="col-sm-4  wow fadeInUp" data-wow-duration="1000ms" data-wow-delay="600ms"> 
        
        <img :src="file_path+sambutan_foto" alt="" class="img-responsive" style="height: 300px; width: 100%;"> <br>
        <p v-html="sambutan_nama" class="text-center"></p>
      </div>
      <div class="col-sm-8 col-xs-12">
        <div class="our-skills wow fadeInLeft" data-wow-duration="1000ms" data-wow-delay="300ms">
          <p  v-html="sambutan_isi"></p>

        </div>
      </div>
      
    </div>
  </div>
</section>

<!--Blog-Section-Start-->
<section id="blog">
  <div class="container">
    <div class="col-md-8 col-md-offset-2">
      <div class="heading">
        <h2>BERITA <span style="color: #115e8d;">TERKINI</span></h2>
        <div class="line"></div>
        <p><span style="color: #115e8d;"><strong>K</strong></span>umpulan berita-berita terkini Bagian Organisasi Konawe Selatan</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">

        <div class="col-md-4 blog-sec" v-for="data in list_berita" :key="data.id">
          <div class="blog-info"> 
            <!-- <img src="images/Blog/1.jpg" class="img-responsive" alt=""> -->
            <img :src="file_path+data.foto" class="img-responsive" alt="" style="height: 250px;">
            <div class="data-meta">
              {{convert_tgl(data.createAt)}}
            </div>
            <a @click="push_berita(data.id)" href="javascript:void(0);">
            <h5>{{data.judul}}</h5>
            </a>
            <ul class="blog-icon">
              <li><i class="fa fa-pencil"></i><a href="javascript:void(0);">
                <h6>{{data.createBy}}</h6>
                </a></li>
              
            </ul>
            <p>{{data.deskripsi}}</p>
            <a @click="push_berita(data.id)" href="javascript:void(0);" class="btn-blg">Selengkapnya</a> </div>
        </div>
        
        
      </div>
    </div>
  </div>
</section>

<br/>
<br/>
<!--Portfolio-Section-Start-->
<section id="portfolio">
  <div class="container">
    <div class="col-md-8 col-md-offset-2">
      <div class="heading">
        <h2>FOTO <span style="color: #115e8d;">TERKINI</span></h2>
        <div class="line"></div>
        <p><span><strong>K</strong></span>umpulan foto-foto terbaru Bagian Organisasi Konawe Selatan</p>
      </div>
    </div>
    
    <div class="portfolio-items">

      <div class="col-md-4 col-sm-6 col-xs-12 portfolio-item creative" v-for="data in list_foto" :key="data.id">
        <div class="portfolio-item-inner"> 
          <img  class="img-responsive " :src="file_path+data.file" alt="" style="height: 250px;">
          <div class="portfolio-info"> <a class="preview" @click="selectDataFoto(data)" href="#" data-toggle="modal" data-target="#m_lihat_data" data-rel="prettyPhoto"><i class="fa fa-plus-circle"></i></a>
            <h6>{{data.judul}}</h6>
            <p>{{data.deskripsi}}</p>
          </div>
        </div>
      </div>
      <!--/.portfolio-item-->
        <div class="modal fade" id="m_lihat_data"  role="dialog">
              <div class="modal-dialog modal-md" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h5 class="modal-title">Detail File</h5>
                          
                      </div>
                      <div class="modal-body text-center">
                          <img :src="file_path+galeryku.file" width="100%" height="auto">
                          <h4>{{ galeryku.judul }}</h4>
                          <p><span>{{ galeryku.deskripsi }}</span></p>
                          
                      </div>
                      <div class="modal-footer">

                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                  </div>
              </div>
          </div>
      
    </div>
  </div>
</section>


<!--Fun-Facts-Section-Start-->
<section id="team">
  <div class="container">
    <div class="col-md-8 col-md-offset-2 text-center">
      <div class="heading">
        <h2>LINK <span style="color: #115e8d;">TERKAIT</span></h2>
        <div class="line"></div>
        <p><span><strong>K</strong></span>umpulan link terkait akses Bagian Organisasi Konawe Selatan</p>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3" v-for="data in list_link_terkait" :key="data.id">
        <div class="fun-fact text-center">
          <a :href="data.url">
              <img :src="file_path+data.file" alt="clients" class="img-responsive ">
          </a>
        </div>
      </div>
      
    </div>
  </div>
</section>

<!--Contact-Section-Start-->
<section id="fun-facts">
  <div class="container">
    <div class="col-md-8 col-md-offset-2 text-center">
      <div class="heading">
        <h2>KONTAK <span style="color: #115e8d;">KAMI</span></h2>
        <div class="line"></div>
        <p><span><strong>S</strong></span>ilahkan menghubungi kami dengan berbagai platform yang tersedia</p>
      </div>
    </div>
    <div class="text-center">
      <div class="col-md-6 col-sm-6 contact-sec-1">
        <h4>INFO<span> KONTAK</span></h4>
        <ul class="contact-form">
          <li><i class="fa fa-map-marker"></i>
            <h6><strong>Alamat :</strong> Jl. Poros Andoolo No.1, Potoro, Kec. Andoolo, Kabupaten Konawe Selatan, Sulawesi Tenggara 93819 </h6>
          </li>
          <li><i class="fa fa-envelope"></i>
            <h6><strong>Email :</strong> <a href="#">ortala@konaweselatankab.go.id</a></h6>
          </li>
          <li><i class="fa fa-phone"></i>
            <h6><strong>Telepon :</strong> +62 823-4133-3955 </h6>
          </li>
          <li><i class="fa fa-wechat"></i>
            <h6><strong>Website:</strong> <a href="https://konaweselatankab.go.id">Kab. Konawe Selatan</a> </h6>
          </li>
        </ul>
      </div>
      <div class="col-md-6 col-sm-6 contact-sec-1 img-responsive" >
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1672.7084513164211!2d122.27573081063176!3d-4.336984443334965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sid!2sid!4v1677060091590!5m2!1sid!2sid"  style="border:0; width: 100%; height: 250px;"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  </div>
</section>


  </div>


</template>


<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

    //const URL_APP = 'http://localhost:5005/';   
    const URL_APP = 'https://server-web.konaweselatankab.go.id/';	
    const UNIT_KERJA = 'PujKg9YgpsKT8BkCE'; // ORTALA
    
    const URL_SLIDER = URL_APP + "api/v1/publish_ortala_slider/";
    const SAMBUTAN_URL = URL_APP + "api/v1/publish_ortala_sambutan/";
    const ADD_URL_BERITA = URL_APP + "api/v1/web_publish_berita/";
    const ADD_URL_FOTO = URL_APP + "api/v1/publish_ortala_foto/";
    const FILE_LOAD = URL_APP + "uploads/";
    const ADD_URL_LINK_TERKAIT = URL_APP + "api/v1/web_publish_link_terkait/";
    const TENTANG_URL = URL_APP + "api/v1/publish_ortala_tentang/";

export default {
  name: 'Home',
  // components: {
  //   HelloWorld
  // }

  data() {
			return {
                id: "",
                list_slider: [],
                list_berita : [],
                list_foto : [],
                list_link_terkait : [],
                unit_kerja : UNIT_KERJA,
                foto: "",

                tentang_isi: "",
                sambutan_nama: "",
                sambutan_foto: "",
                sambutan_isi: "",

                file_path : FILE_LOAD,
                galeryku : {
                    id : '',
                    judul : '',
                    deskripsi : '',
                    file : '',
                    unit_kerja : '',
                    status : '',
                    createBy : '',
                    createAt : '',
                },
			}
		},

    methods: {
            getSlider: function() {
                fetch(URL_SLIDER, {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_slider = res_data
                        // console.log(res_data);

                });
            },
            getListBerita: function() {
                this.cek_load_data_list = true;
                fetch(ADD_URL_BERITA+"homeOPD", {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                        body: JSON.stringify({
                        unit_kerja: this.unit_kerja,

                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_berita = res_data;
                        // this.cek_load_data = false;
                        // console.log(res_data);
                });
            },
            getTentang: function() {
               fetch(TENTANG_URL, {
                   method: "get",
                   headers: {
                       "content-type": "application/json",
                   },
               })
               .then(res => res.json())
               .then(res_data => {
                   var tampil_data = res_data[0];
                   this.tentang_isi = tampil_data.uraian;
                   // console.log(res_data);
               });
           },
			getSambutan: function() {
                fetch(SAMBUTAN_URL + "view", {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },

                })
                    .then(res => res.json())
                    .then(res_data => {
                        var sambutan = res_data[0];

                        this.sambutan_nama = sambutan.nama;
                        this.sambutan_foto = sambutan.foto;
                        this.sambutan_isi = sambutan.isi;
                        // console.log(res_data);

                    });
            },
            getFoto: function() {
                fetch(ADD_URL_FOTO+"home", {
                    method: "get",
                    headers: {
                        "content-type": "application/json",
                    },
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_foto = res_data
                        // console.log(res_data);

                });
            },
            getLinkTerkait: function() {
                fetch(ADD_URL_LINK_TERKAIT, {
                    method: "POST",
                    headers: {
                    "content-type": "application/json",
                    authorization: "kikensbatara " + localStorage.token
                    },
                        body: JSON.stringify({
                        unit_kerja: this.unit_kerja,

                    })
                })
                    .then(res => res.json())
                    .then(res_data => {
                        this.list_link_terkait = res_data;
                        // this.cek_load_data = false;
                        // console.log(res_data);
                });
            },
            selectDataFoto: function(data) {
                // console.log(data);
                this.galeryku = {
                    id : data.id,
                    judul : data.judul,
                    deskripsi : data.deskripsi,
                    file : data.file,
                    unit_kerja : data.unit_kerja,
                    status : data.status,
                    createBy : data.createBy,
                    createAt : data.createAt,
                };
            },
            cek_active : function(data){
                if (data == 0) {
                    return 'active'
                }
            },
            push_berita : function(id){
                this.$router.push('/isi_berita?id='+id);
            },
            buka_modal: function(modalku) {
                var modal = document.getElementById(modalku);
                modal.style.display = "block";
            },

            tutup_modal: function(modalku) {
                var modal = document.getElementById(modalku);
                modal.style.display = "none";
            },

            convert_tgl : function(dateString){
                  var date = new Date(dateString);
                  var getBulan = date.getMonth() + 1; var bulan = '';
                  if (getBulan == '1') {bulan = 'Jan'}
                  else if(getBulan == '2') {bulan = 'Feb'}
                  else if(getBulan == '3') {bulan = 'Mar'}
                  else if(getBulan == '4') {bulan = 'Apr'}
                  else if(getBulan == '5') {bulan = 'Mei'}
                  else if(getBulan == '6') {bulan = 'Jun'}
                  else if(getBulan == '7') {bulan = 'Jul'}
                  else if(getBulan == '8') {bulan = 'Agt'}
                  else if(getBulan == '9') {bulan = 'Sep'}
                  else if(getBulan == '10') {bulan = 'Okt'}
                  else if(getBulan == '11') {bulan = 'Nov'}
                  else if(getBulan == '12') {bulan = 'Des'}



                  return date.getDate() + " " + bulan + " " + date.getFullYear();
              },

        },



		mounted() {

            this.getSambutan();
            this.getTentang();
            this.getSlider();
            this.getListBerita();
            this.getFoto();
            this.getLinkTerkait();

		},


}
</script>


<style scoped>

p {
  font-family: 'open_sansregular' !important;
  font-weight: normal !important;
}

.cssnya {
  width: auto !important; 
  height: 100% !important;
}
.carousel-inner {
  width: auto; 
  height: 100%;
}
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
  .cssnya {
  height: 300px !important;
}
  .carousel-inner {
    /* background-color: blue;
    color: white; */
    /* width: auto;  */
    height: 330px;
  }

  .carousel-caption {
    /* background-color: blue;
    color: white; */
    /* width: auto;  */
    top: 30% !important;
  }
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .cssnya {
    margin-top: 30px !important;
  height: 300px !important;
}
  .carousel-inner {
    /* background-color: olive;
    color: white; */
    /* width: auto;  */
  height: 330px;
  }
  .carousel-caption {
    /* background-color: blue;
    color: white; */
    /* width: auto;  */
    top: 30% !important;
  }
}

</style>