import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'




import headers  from './components/Header.vue';
Vue.component('headers', headers);

import footers  from './components/Footer.vue';
Vue.component('footers', footers);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
