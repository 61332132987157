<template>
    <div>
        <!--Navigation-->
            <header id="menu">
            <div class="navbar navbar-default navbar-fixed-top" style="background-color: #8D4011">
                <div class="container">
                <div class="container-fluid">
                    <!-- Brand and toggle get grouped for better mobile display -->
                    <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false"> <span class="sr-only">Toggle navigation</span> <span class="icon-bar"></span> <span class="icon-bar"></span> <span class="icon-bar"></span> </button>
                    <router-link to="/"> <a class="navbar-brand"  href="javascript:void(0)" :to="'/'"><img src="images/logo1.png" alt="" width="100%" height="70%"></a> </router-link></div>
                    <!-- Collect the nav links, forms, and other content for toggling -->
                    <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav">
                        <li class="active"> <router-link to="/">  <a class="scroll" href="javascript:void(0)">Beranda</a> </router-link>  </li>
                        <li> <router-link to="/Berita"> <a class="scroll" href="javascript:void(0)" :to="'/Berita'">Berita</a> </router-link> </li>
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Profil">Profil <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background-color: #8D4011;">
                                <li> <router-link to="/tugas_pokok"> <a href="javascript:void(0)" :to="'/tugas_pokok'">Tugas Pokok</a> </router-link> </li>
                                <li> <router-link to="/struktur_organisasi"> <a href="javascript:void(0)" :to="'/struktur_organisasi'">Struktur Organisasi</a> </router-link> </li>
                                <li> <router-link to="/tujuan_sasaran"> <a href="javascript:void(0)" :to="'/tujuan_sasaran'">Tujuan & Sasaran</a> </router-link> </li>
                                <li> <router-link to="/visi_misi"> <a href="javascript:void(0)" :to="'/visi_misi'">Visi & Misi</a> </router-link> </li>
                                     
                            </ul>
                        </li>
                       
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Layanan">Layanan <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background-color: #8D4011;">
                                <li>   <a @click="push_link('5qj65l3d8lrtbpmam')" >Maklumat Pelayanan Publik</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw4lasu')"  >Akuntabilitas</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw4ls4e')"  >Organisasi</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw4q9go')"  >Ketatalaksanaan</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw4wgtu')"  >Analisis Jabatan</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw4z5ue')"  >Reformasi Birokrasi</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw54tz8')"  >Konsultasi</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw553gn')"  >Fasilitasi</a>  </li>
                                <li>   <a @click="push_link('5qj65l7xwlrw55gmq')"  >Permintaan Data</a>  </li>
                                
                                
                                
                                     
                            </ul>
                        </li>
                        <li  class="dropdown">
                            <a class="scroll dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#Galeri">Galeri <span class="caret"></span></a>
                            <ul class="dropdown-menu" style="background-color: #8D4011;">
                                <li> <router-link to="/foto"> <a href="javascript:void(0)" :to="'/foto'">Foto</a> </router-link> </li>
                                <li> <router-link to="/video"> <a href="javascript:void(0)" :to="'/video'">Video</a> </router-link> </li>
                                
                            </ul>
                        </li>
                        <li> <router-link to="/ortala_dokumen"> <a class="scroll" href="javascript:void(0)" :to="'/ortala_dokumen'">Dokumen Lainnya</a> </router-link> </li>
                        
                      
                        
                        
                    </ul>
                    
                    </div>
                    <!-- /.navbar-collapse -->
                </div>
                <!-- /.container-fluid -->
                </div>
            </div>
            </header>
    </div>
</template>


<script>
export default {

    methods: {
        
        push_link : function(id){
            this.$router.push('/layanan?id='+id);
            location.reload();
        },

    },
}
</script>